<template>
    <v-container class="fill-height pa-0">
        <v-row
            no-gutters
            justify="center"
        >
            <v-col cols="auto">
                <div
                    class="digit"
                    @click.stop.prevent
                    @selectstart.stop.prevent
                >
                    {{ digit }}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            digit: {
                type: Number,
                default: 0
            }
        }
    };
</script>

<style lang="scss" scoped>
    div.digit {
        line-height: 1;
        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }
</style>
