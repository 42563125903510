import Vue from 'vue';
import Vuex from 'vuex';
import syncStoragePlugin from './sync-storage-plugin';
import { SYSTEM } from '../app/constants';
import isTouchCapable from '../app/touch-detect';
import { DateTime } from 'luxon';
import * as device from 'mobile-device-detect';

Vue.use(Vuex);

const hasTouch = isTouchCapable();

export default new Vuex.Store({
    strict: true,

    plugins: [syncStoragePlugin],

    state: {
        appVersion: '[AIV]{version}[/AIV]',
        displayCells: false,
        hasTouch,
        needsKeyboard: hasTouch && device.isMobile,
        detectedDevice: device,
        settings: {
            showErrors: true,
            showPossibles: false,
            showKeyboard: false,
            showKeyboardControlKeys: true,
            difficulty: 1,
            puzzlePosition: 'center',
            theme: SYSTEM
        },
        activePuzzleName: null,
        activePuzzle: {
            index: null,
            data: null,
            difficulty: null,
            changes: [],
            file: null,
            created: null,
            modified: null,
            solved: null,
            lastChangedCell: null
        },
        storedPuzzles: [],
        puzzleObjectURL: null,
        qrCodeURL: null
    },

    mutations: {
        setDisplayCells(state, payload) {
            state.displayCells = payload;
        },

        /* Syncs with storage */
        initSettings() {
            /* sync plugin will use store defaults... */
        },

        /* Syncs with storage */
        loadSettings(state, payload) {
            if (payload !== null) {
                for (const prop in payload) {
                    if (prop in state.settings) {
                        state.settings[prop] = payload[prop];
                    }
                }
            }
        },

        /* Syncs with storage */
        updateSettings(state, payload) {
            state.settings[payload.name] = payload.value;
        },

        /* Syncs with storage */
        setActivePuzzleName(state, name) {
            state.activePuzzleName = name;
        },

        setActivePuzzle(state, payload) {
            for (const prop in payload) {
                if (prop in state.activePuzzle) {
                    if (prop === 'changes') {
                        if (payload.changes.length) {
                            state.activePuzzle.changes.splice(0);
                            state.activePuzzle.changes.push(...payload.changes);
                        }
                    } else {
                        state.activePuzzle[prop] = payload[prop];
                    }
                }
            }
        },

        /* Syncs with storage */
        addChange(state, change) {
            const [r, c, v, o] = [
                change.rowIndex,
                change.colIndex,
                (change.value || 0),
                (change.oldValue || 0)
            ];

            state.activePuzzle.lastChangedCell = { r, c };
            state.activePuzzle.modified = DateTime.utc().toMillis();
            state.activePuzzle.changes.unshift(`${r}${c}${v}${o}`);
        },

        /* Syncs with storage */
        removeChange(state) {
            state.activePuzzle.changes.shift();
        },

        resetChanges(state) {
            state.activePuzzle.changes.splice(0);
        },

        /* Syncs with storage */
        clearChanges(state) {
            state.activePuzzle.changes.splice(0);
        },

        updateQRCodeURL(state, payload) {
            state.qrCodeURL = payload;
        }
    }
});
