<template>
    <v-menu
        v-model="showMenu"
        rounded
        bottom
        :nudge-bottom="50"
        :close-on-content-click="false"
        max-width="350"
        min-width="320"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                icon
                class="settings-menu-button"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Settings</v-card-title>

            <v-divider />

            <!-- Difficulty Levels -->
            <v-card-text>
                <span class="subheading">
                    Select Default Difficulty
                </span>
                <v-chip-group
                    v-model="difficulty"
                    active-class="primary"
                    column
                    mandatory
                >
                    <v-chip
                        v-for="level in difficultyLevels"
                        :key="level.value"
                        class="mt-1"
                        label
                        :small="$vuetify.breakpoint.xsOnly"
                        :value="level.value"
                    >
                        {{ level.description }}
                    </v-chip>
                </v-chip-group>
            </v-card-text>

            <v-divider />

            <!-- Options -->
            <v-card-text>
                <span class="subheading">
                    Puzzle Options
                </span>
                <v-checkbox
                    v-model="showPossibles"
                    label="Show Candidates"
                    color="primary"
                    hide-details
                />
                <v-checkbox
                    v-model="showErrors"
                    label="Show Conflicts & Errors"
                    color="primary"
                    hide-details
                />
                <v-checkbox
                    v-if="!$store.state.needsKeyboard"
                    v-model="showKeyboard"
                    label="Enable Keyboard"
                    color="primary"
                    hide-details
                />
                <v-checkbox
                    v-if="$store.state.needsKeyboard || showKeyboard"
                    v-model="showKeyboardControlKeys"
                    :class="checkboxIndentLevel"
                    label="Show Control Keys"
                    color="primary"
                    hide-details
                />
            </v-card-text>

            <v-divider />

            <!-- Themes -->
            <v-card-text>
                <span class="subheading">
                    Select Theme
                </span>

                <v-chip-group
                    v-model="theme"
                    active-class="primary"
                    column
                    mandatory
                >
                    <v-chip
                        v-for="appTheme in appThemes"
                        :key="appTheme.value"
                        class="ml-0 my-1 mr-1"
                        label
                        :small="$vuetify.breakpoint.xsOnly"
                        :value="appTheme.value"
                    >
                        <v-icon
                            :class="appTheme.value === 1 ? 'mdi-rotate-45' : null"
                            :small="$vuetify.breakpoint.xsOnly"
                            left
                        >
                            {{ appTheme.icon }}
                        </v-icon>
                        {{ appTheme.description }}
                    </v-chip>
                </v-chip-group>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    text
                    color="primary"
                    @click="showMenu = false"
                >
                    close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    import isTouchCapable from '../app/touch-detect';
    import { enforceBounds } from '../app/utils';

    export default {
        data: () => ({
            showMenu: false
        }),

        computed: {
            hasTouch: {
                get() {
                    return isTouchCapable();
                }
            },

            checkboxIndentLevel: {
                get() {
                    return {
                        'pl-6': !this.$store.state.needsKeyboard
                    };
                }
            },

            theme: {
                get() {
                    return this.$store.state.settings.theme;
                },

                set(value) {
                    this.$store.commit({
                        name: 'theme',
                        type: 'updateSettings',
                        value
                    });

                    this.$emit('theme-changed');
                }
            },

            difficultyLevels: {
                get() {
                    return [
                        { value: 0, description: 'Extra Easy' },
                        { value: 1, description: 'Easy' },
                        { value: 2, description: 'Medium' },
                        { value: 3, description: 'Hard' }
                    ];
                }
            },

            appThemes: {
                get() {
                    const device = this.$store.state.detectedDevice;
                    let systemIcon = 'mdi-desktop-tower-monitor';

                    if (device.isMobile) {
                        systemIcon = device.isTablet ? 'mdi-tablet-ipad' : 'mdi-cellphone-iphone';
                    } else {
                        if (device.isMacOs) {
                            systemIcon = 'mdi-desktop-mac';
                        }
                    }

                    return [
                        { value: 0, description: 'Light', icon: 'mdi-white-balance-sunny' },
                        { value: 1, description: 'Dark', icon: 'mdi-moon-waxing-crescent' },
                        { value: 2, description: 'System', icon: systemIcon }
                    ];
                }
            },

            puzzlePosition: {
                get() {
                    return this.$store.state.settings.puzzlePosition;
                },

                set(value) {
                    this.$store.commit({
                        name: 'puzzlePosition',
                        type: 'updateSettings',
                        value
                    });
                }
            },

            showKeyboard: {
                get() {
                    return this.$store.state.settings.showKeyboard;
                },

                set(value) {
                    this.$store.commit({
                        name: 'showKeyboard',
                        type: 'updateSettings',
                        value
                    });

                    this.$emit('show-keyboard-setting-changed', value);
                }
            },

            showKeyboardControlKeys: {
                get() {
                    return this.$store.state.settings.showKeyboardControlKeys;
                },

                set(value) {
                    this.$store.commit({
                        name: 'showKeyboardControlKeys',
                        type: 'updateSettings',
                        value
                    });
                }
            },

            showPossibles: {
                get() {
                    return this.$store.state.settings.showPossibles;
                },

                set(value) {
                    this.$store.commit({
                        name: 'showPossibles',
                        type: 'updateSettings',
                        value
                    });
                }
            },

            showErrors: {
                get() {
                    return this.$store.state.settings.showErrors;
                },

                set(value) {
                    this.$store.commit({
                        name: 'showErrors',
                        type: 'updateSettings',
                        value
                    });
                }
            },

            difficulty: {
                get() {
                    return this.$store.state.settings.difficulty;
                },

                set(value) {
                    this.$store.commit({
                        name: 'difficulty',
                        type: 'updateSettings',
                        value: enforceBounds(value, 0, 3)
                    });
                }
            }
        }
    };
</script>
