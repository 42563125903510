export const UP = Symbol('UP');
export const DOWN = Symbol('DOWN');
export const LEFT = Symbol('LEFT');
export const RIGHT = Symbol('RIGHT');

export const LIGHT = 0;
export const DARK = 1;
export const SYSTEM = 2;

// Number of puzzles available to select from
export const NUM_PUZZLES = 1000000;

// puzzles come in files with BLOCK_SIZE length
export const BLOCK_SIZE = 10000;

export const SUDOKU_DATA_RANGE = 80;

// length of row = 164: (unsolved: 81) + (comma: 1) + (solved: 81) + (LF: 1)
// export const ROW_LEN = 164;

// export const ROW_START = 82;

// // length to end = 162: (row length: 164) - (LF: 1) - ("inclusive off by 1": 1)
// export const ROW_END = 162;

export const RequiredNumberType = { type: Number, required: true };
export const RequiredObjectType = { type: Object, required: true };
export const RequiredBooleanType = { type: Boolean, required: true };

// export const foobar = {
// return indices.map((i) => String.fromCodePoint(foobar[pza[i]])).join('');
// const cza = Array.from(str, (c) => foobar[c.codePointAt(0)]);

//     1: 0x1f350,
//     2: 0x1f34a,
//     3: 0x1f352,
//     4: 0x1f349,
//     5: 0x1f353,
//     6: 0x1f34f,
//     7: 0x1f34b,
//     8: 0x1f351,
//     9: 0x1f34d,
//     0x1f350: 1,
//     0x1f34a: 2,
//     0x1f352: 3,
//     0x1f349: 4,
//     0x1f353: 5,
//     0x1f34f: 6,
//     0x1f34b: 7,
//     0x1f351: 8,
//     0x1f34d: 9,
// };

//  845261: Faithful-Black-Cat //- 192728
