<template>
    <v-menu
        v-model="showMenu"
        max-width="320"
        min-width="320"
        rounded
        bottom
        :nudge-bottom="50"
        :close-on-content-click="false"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                icon
                class="puzzle-menu-button"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon large>
                    mdi-grid
                </v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-row
                class="pl-4 pr-3"
                no-gutters
            >
                <v-col
                    class="ma-auto"
                    cols="auto"
                >
                    <v-card-title class="pl-0">
                        Puzzles
                    </v-card-title>
                </v-col>
                <v-spacer />
                <v-col
                    class="ma-auto"
                    align-self="center"
                    cols="auto"
                >
                    <v-btn
                        text
                        color="primary lighten-1"
                        @click="createNewPuzzle"
                    >
                        new
                        <v-icon right>
                            mdi-plus-circle
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider />

            <v-card-text class="pt-2">
                <v-row no-gutters>
                    <v-col
                        class="ma-auto"
                        cols="auto"
                    >
                        <div class="subheading">
                            ACTIVE PUZZLE
                        </div>
                    </v-col>
                    <v-spacer />
                    <v-col
                        class="ma-auto pr-4"
                        cols="auto"
                    >
                        <v-btn
                            v-if="activePuzzleHasChanges"
                            icon
                            color="primary lighten-1"
                            @click="showRestartPuzzleDialog"
                        >
                            <v-icon>
                                mdi-restart
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col
                        class="ma-auto"
                        cols="auto"
                    >
                        <v-btn
                            :disabled="!isSecure"
                            icon
                            color="primary lighten-1"
                            @click="copyLinkToClipboard"
                        >
                            <v-icon>mdi-link-variant</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col
                        class="ma-auto"
                        cols="auto"
                    >
                        <v-btn
                            icon
                            color="primary lighten-1"
                            @click="handleShowQRCodeClick"
                        >
                            <v-icon>mdi-qrcode-scan</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <div
                    class="active-puzzle text-subtitle-2 pt-2"
                    @click="showPuzzleIndex = !showPuzzleIndex"
                >
                    {{ activePuzzleName }}
                    <span
                        v-show="showPuzzleIndex"
                        class="puzzle-index"
                    > #{{ activePuzzle.index }}</span>
                </div>
                <div class="font-weight-normal">
                    {{ `${percentComplete}% complete` }}
                    <v-icon
                        v-if="percentComplete === 100"
                        class="pl-2 puzzle-completed-icon"
                        small
                    >
                        mdi-check-circle
                    </v-icon>
                    <!-- <v-icon
                        v-if="percentComplete === 100"
                        class="pl-2 puzzle-error-icon"
                        small
                    >
                        mdi-close-circle
                    </v-icon> -->
                </div>
                <div class="text-caption">
                    ({{ difficulty }})
                </div>
            </v-card-text>
            <v-card-text
                v-if="showQRCode && qrCodeURL"
                class="pt-0"
            >
                <v-img
                    class="ma-auto qr-code"
                    :src="$store.state.qrCodeURL"
                    width="175px"
                    height="175px"
                />
            </v-card-text>

            <v-card-actions v-if="activePuzzleName">
                <transition
                    name="fade"
                    @after-enter="showLinkCaption = false"
                >
                    <div
                        v-show="showLinkCaption"
                        class="px-2 text-caption text-right font-weight-light"
                    >
                        link has been copied to the clipboad
                    </div>
                </transition>

                <v-spacer />

                <v-btn
                    text
                    color="primary"
                    @click="showMenu = !showMenu"
                >
                    close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: 'PuzzlesMenu',

        props: {
            percentComplete: {
                type: Number,
                required: true
            },

            updateQrCode: null
        },

        data: () => ({
            menuState: false,
            showQRCode: false,
            showPuzzleIndex: false,
            showLinkCaption: false
        }),

        computed: {
            showMenu: {
                get() {
                    return this.menuState && this.activePuzzleName;
                },

                set(value) {
                    this.menuState = value;
                    if (!value) {
                        this.showPuzzleIndex = false;
                        this.showQRCode = false;
                    }
                }
            },

            activePuzzleName: {
                get() {
                    return this.$store.state.activePuzzleName;
                }
            },

            activePuzzle: {
                get() {
                    return this.$store.state.activePuzzle;
                }
            },

            activePuzzleHasChanges: {
                get() {
                    return this.$store.state.activePuzzle.changes.length > 0;
                }
            },

            qrCodeURL: {
                get() {
                    return this.$store.state.qrCodeURL;
                }
            },

            difficulty: {
                get() {
                    const difficultyMap = {
                        0: 'Extra Easy',
                        1: 'Easy',
                        2: 'Medium',
                        3: 'Hard'
                    };

                    return difficultyMap[this.activePuzzle.difficulty];
                }
            }
        },

        created() {
            this.isSecure = window.isSecureContext;
        },

        methods: {
            createNewPuzzle() {
                this.$emit('create-new-puzzle');
            },

            copyLinkToClipboard() {
                this.$emit('copy-link-to-clipboard');
                this.showLinkCaption = true;
            },

            async handleShowQRCodeClick() {
                if (!this.showQRCode) {
                    await this.$props.updateQrCode();
                }

                this.showQRCode = !this.showQRCode;
            },

            showRestartPuzzleDialog() {
                this.showMenu = false;
                this.$emit('show-restart-dialog');
            }
        }
    };
</script>

<style lang="scss" scoped>

    .active-puzzle {
        cursor: pointer;
    }

    .theme--light.v-application .puzzle-completed-icon {
        color: var(--v-success-darken1);
    }

    .theme--dark.v-application .puzzle-completed-icon {
        color: var(--v-success-lighten2);
    }

    .theme--light.v-application .puzzle-error-icon {
        color: var(--v-error-darken2);
    }

    .theme--dark.v-application .puzzle-error-icon {
        color: var(--v-error-lighten2);
    }

    .fade-enter-active {
        transition: opacity 0.75s;
    }

    .fade-leave-active {
        transition: opacity 0.75s 3s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
