<template>
    <div
        class="cell"
        :class="cellStyleClasses"
        @click.stop.prevent
        @selectstart.stop.prevent
        @pointerdown.stop="onPointerDown"
    >
        <cell-digit
            v-show="display"
            v-if="cellHasValue"
            :digit="gridCell.digit"
        />

        <cell-possibles
            v-show="display"
            v-if="showPossibles && hasPossibles"
            :possibles="gridCell.possibles.values"
        />

        <cell-conflict-indicator
            v-show="display"
            v-if="showConflictIndicator"
        />

        <cell-impossible
            v-show="display"
            v-if="isImpossible"
        />
    </div>
</template>

<script>

    import CellDigit from './CellDigit';
    import CellPossibles from './CellPossibles';
    import CellImpossible from './CellImpossible';
    import CellConflictIndicator from './CellConflictIndicator';

    import { RequiredObjectType, RequiredBooleanType } from '../app/constants';

    export default {

        components: {
            CellDigit,
            CellPossibles,
            CellImpossible,
            CellConflictIndicator
        },

        props: {
            gridCell: RequiredObjectType,
            display: RequiredBooleanType
        },

        data: () => ({
            isSelected: false
        }),

        computed: {
            index: {
                get() {
                    return this.gridCell.index;
                }
            },

            rowIndex: {
                get() {
                    return this.gridCell.rowIndex;
                }
            },

            colIndex: {
                get() {
                    return this.gridCell.colIndex;
                }
            },

            blockIndex: {
                get() {
                    return this.gridCell.blockIndex;
                }
            },

            hasPossibles: {
                get() {
                    return !this.gridCell.possibles.values.empty;
                }
            },

            cellStyleClasses: {
                get() {
                    return {
                        selected: this.display && this.isSelected,
                        given: this.gridCell.isGiven,
                        modified: this.isModified,
                        invalid: this.display && this.isInvalid,
                        impossible: this.display && this.isImpossible,
                        shaded: !this.isInvalid && (this.blockIndex & 1) === 0,
                        'invalid-selected': this.display && this.isSelected && this.isInvalid,
                        'impossible-selected': this.display && this.isSelected && this.isImpossible,
                        'outer-top': this.rowIndex === 0,
                        'outer-left': this.colIndex === 0,
                        'outer-right': this.colIndex === 8,
                        'outer-bottom': this.rowIndex === 8,
                        'inner-right': this.colIndex < 8,
                        'inner-bottom': this.rowIndex < 8,
                        'breakpoint-xs': this.$vuetify.breakpoint.xsOnly
                    };
                }
            },

            isModified: {
                get() {
                    return (
                        this.cellHasValue &&
                        !this.gridCell.isGiven &&
                        !this.isImpossible
                    );
                }
            },

            isInvalid: {
                get() {
                    return (
                        this.showErrors &&
                        this.cellHasValue &&
                        !this.gridCell.isValid
                    );
                }
            },

            isImpossible: {
                get() {
                    return this.showErrors && this.gridCell.isImpossible;
                }
            },

            showConflictIndicator: {
                get() {
                    let result = false;
                    const lastChanged = this.$store.state.activePuzzle.lastChangedCell;

                    if (lastChanged !== null) {
                        result = this.isInvalid &&
                            this.rowIndex === lastChanged.r &&
                            this.colIndex === lastChanged.c;
                    }

                    return result;
                }
            },

            cellHasValue: {
                get() {
                    return this.gridCell.hasValue;
                }
            },

            showPossibles: {
                get() {
                    return (
                        this.$store.state.settings.showPossibles &&
                        !this.gridCell.parent.isEmpty &&
                        !this.cellHasValue
                    );
                }
            },

            showErrors: {
                get() {
                    return this.$store.state.settings.showErrors;
                }
            }
        },

        mounted() {
            this.gridCell.dispatcher.on(
                'cellChanged',
                this.onGridCellChanged.bind(this)
            );
        },

        methods: {
            onPointerDown() {
                if (this.display) {
                    this.$emit('cell-select', this);
                }
            },

            onGridCellChanged(change) {
                this.$emit('cell-changed', {
                    rowIndex: change.cell.rowIndex,
                    colIndex: change.cell.colIndex,
                    type: change.type,
                    value: change.value,
                    oldValue: change.oldValue
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    $shaded: var(--cell-shaded-color); //#f1f1f1;
    $outer_border: var(--cell-outer-border);
    $inner_border: var(--cell-inner-border);
    $modified-fg: var(--modified-font-color); //#0042ff;
    $invalid-bg: var(--cell-invalid-color); //#aa000055;
    $invalid-fg: var(--invalid-font-color);
    $given-fg: var(--given-font-color);
    $selected: var(--cell-selected-color);

    div.cell {
        flex-grow: 0;
        flex-shrink: 0;
        background: var(--cell-color);
        width: var(--cell-size);
        height: var(--cell-size);
        font-size: var(--cell-font-size);
        cursor: default;
        line-height: 1;
        position: relative;
        z-index: 1;
    }

    div.cell.breakpoint-xs {
        font-weight: 900;
    }

    div.cell.given {
        color: $given-fg;
    }

    div.cell.modified {
        color: $modified-fg;
    }

    div.cell.invalid {
        background: $invalid-bg;
    }

    div.cell.shaded {
        background: $shaded;
    }

    div.cell.selected {
        background: $selected;
    }

    div.cell.invalid-selected, div.cell.impossible-selected {
        background: repeating-linear-gradient(
            -45deg,
            $invalid-bg,
            $invalid-bg 10px,
            $selected 10px,
            $selected 20px
        ) !important;
    }

    div.cell.impossible {
        color: $invalid-fg;
        background: $invalid-bg;
    }

    div.cell.outer-left {
        border-left: $outer_border;
    }

    div.cell.outer-top {
        border-top: $outer_border;
    }

    div.cell.outer-right {
        border-right: $outer_border;
    }

    div.cell.outer-bottom {
        border-bottom: $outer_border;
    }

    div.cell.inner-right {
        border-right: $inner_border;
    }

    div.cell.inner-bottom {
        border-bottom: $inner_border;
    }
</style>
