
export class MapSetArray {
    constructor() {
        this._create();
    }

    _create() {
        this.items = Object.create(null);
        this.length = 0;
    }

    has(item) {
        return this.items[item] !== undefined;
    }

    add(item, key = null) {
        if (!this.has(key || item)) {
            this.items[key || item] = item;
            this.length += 1;
        }
    }

    delete(item, key = null) {
        const result = delete this.items[key || item];
        this.length -= result ? 1 : 0;
        return result;
    }

    get(key) {
        return this.items[key];
    }

    clear() {
        this._create();
    }

    toArray() {
        return this.values.slice();
    }

    toSet() {
        return new Set(this.values);
    }

    get values() {
        return Object.values(this.items);
    }

    get keys() {
        return Object.keys(this.items);
    }

    get onlyValue() {
        let result = null;

        if (this.length === 1) {
            result = this.values[0];
        }

        return result;
    }

    get empty() {
        return this.length === 0;
    }
}
