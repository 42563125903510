import settingsStorage from '../app/storage/settings-storage';
import puzzleStorage from '../app/storage/puzzle-storage';

const syncSettingsMutations = ['initSettings', 'updateSettings'];
const syncChangesMutations = ['addChange', 'removeChange', 'clearChanges'];

// eslint-disable-next-line no-extend-native
String.prototype.foundIn = function foundIn(arr) {
    return arr.includes(this.valueOf());
};

export default (store) => {
    store.subscribe(async (mutation) => {
        if (mutation.type.foundIn(syncSettingsMutations)) {
            await settingsStorage.setItem('settings', store.state.settings);
        } else if (mutation.type === 'setActivePuzzleName') {
            const name = store.state.activePuzzleName;
            if (name !== undefined || name !== null) {
                await settingsStorage.setItem('activePuzzleName', name);
            }
        } else if (mutation.type.foundIn(syncChangesMutations)) {
            const name = store.state.activePuzzleName;
            if (name !== undefined || name !== null) {
                await puzzleStorage.setItem(
                    store.state.activePuzzleName,
                    store.state.activePuzzle
                );
            }
        }
    });
};
