import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: colors.indigo,
                secondary: colors.grey
            },
            dark: {
                primary: colors.indigo.lighten3,
                secondary: colors.grey,
                accent: colors.indigo
            }
        }
    }
});
