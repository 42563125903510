<template>
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="320"
    >
        <v-card @selectstart.prevent>
            <v-card-title>
                Restart Puzzle?
            </v-card-title>
            <!-- <v-divider /> -->
            <v-card-text>
                This will start the puzzle from the very beginning and all changes will be lost.
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    text
                    color="primary lighten-1"
                    @click="$emit('close-dialog')"
                >
                    cancel
                </v-btn>
                <v-btn
                    text
                    color="primary lighten-1"
                    @click="performRestart"
                >
                    restart
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'RestartPuzzleDialog',

        data: () => ({
            showDialog: true
        }),

        methods: {
            performRestart() {
                this.$emit('close-dialog');
                this.$emit('restart-puzzle');
            }
        }
    };
</script>
