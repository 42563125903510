<template>
    <v-app v-resize="onResize">
        <keyboard-events @keydown="onKeyDown" />

        <prefers-color-scheme-event
            v-if="isSystemTheme"
            @prefers-color-scheme="setTheme"
        />

        <restart-puzzle-dialog
            v-if="showRestartPuzzleDialog"
            @restart-puzzle="restartPuzzle"
            @close-dialog="showRestartPuzzleDialog = false"
        />

        <v-app-bar
            app
            flat
            dense
            color="primary"
        >
            <!-- Puzzles menu -->
            <puzzles-menu
                :percent-complete="percentComplete"
                :update-qr-code="updateQRCodeHandler"
                @show-restart-dialog="showRestartPuzzleDialog = true"
                @create-new-puzzle="createNewPuzzle"
                @copy-link-to-clipboard="copyToClipboard"
            />

            <v-toolbar-title
                class="pl-1"
                @click="showAppVersion = !showAppVersion"
            >
                Sudoku Studio
                <transition
                    name="fade"
                    @after-enter="showAppVersion = false"
                >
                    <span
                        v-show="showAppVersion"
                        class="app-version"
                    >
                        - {{ appVersion }}
                    </span>
                </transition>
            </v-toolbar-title>

            <v-spacer />

            <!-- settings menu -->
            <settings-menu
                @show-keyboard-setting-changed="showKeyboardSettingChanged"
                @theme-changed="setTheme"
            />
        </v-app-bar>

        <v-main>
            <v-container
                class="fill-height puzzle-container"
                :class="$vuetify.breakpoint.xsOnly ? 'pa-1' : 'pa-auto'"
                @selectstart.prevent
            >
                <v-container
                    v-show="displayGrid"
                    class="puzzle pa-0"
                >
                    <v-row
                        v-for="(_, r) in 9"
                        :key="r"
                        no-gutters
                        :justify="puzzlePosition"
                        align="center"
                    >
                        <v-col
                            class="d-flex"
                            cols="auto"
                        >
                            <sudoku-cell
                                v-for="(__, c) in 9"
                                :key="`${r}:${c}`"
                                :ref="`${r}:${c}`"
                                :grid-cell="grid.cell(r, c)"
                                :display="$store.state.displayCells"
                                @cell-changed="onCellChanged"
                                @cell-select="onCellSelect"
                            />
                        </v-col>
                    </v-row>
                    <touch-keyboard
                        v-if="needsKeyboard || showKeyboard"
                        ref="keyboard"
                        :active-cell="activeCell"
                        :keyboard-position="puzzlePosition"
                        @move-keyboard="adjustPuzzlePosition"
                        @touch-key-digit="onTouchKeyDigit"
                        @touch-key-backspace="onTouchKeyBackspace"
                        @touch-key-return="onTouchKeyReturn"
                        @touch-key-arrow="onTouchKeyArrow"
                    />
                </v-container>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import { Grid } from './sudoku/Grid';

    import SudokuCell from './components/SudokuCell';
    import KeyboardEvents from './components/KeyboardEvents';
    import PrefersColorSchemeEvent from './components/PrefersColorSchemeEvent';
    import TouchKeyboard from './components/TouchKeyboard';
    import SettingsMenu from './components/SettingsMenu';
    import PuzzlesMenu from './components/PuzzlesMenu.vue';
    import RestartPuzzleDialog from './components/RestartPuzzleDialog';

    import puzzleStorage from './app/storage/puzzle-storage';
    import settingsStorage from './app/storage/settings-storage';
    // import { range, enforceBounds, createUniqueName } from './app/utils';
    import { enforceBounds } from './app/utils';

    import {
        // NUM_PUZZLES, BLOCK_SIZE, SUDOKU_DATA_RANGE,
        UP, DOWN, LEFT, RIGHT, DARK, SYSTEM
    } from './app/constants';

    import QRCode from 'qrcode';
    import { DateTime } from 'luxon';

    export default {
        name: 'App',

        components: {
            SudokuCell,
            KeyboardEvents,
            PrefersColorSchemeEvent,
            TouchKeyboard,
            SettingsMenu,
            PuzzlesMenu,
            RestartPuzzleDialog
        },

        data: () => ({
            displayGrid: false,
            activeCell: null,
            grid: new Grid(),
            showAppVersion: false,
            showRestartPuzzleDialog: false
        }),

        computed: {
            isSystemTheme: {
                get() {
                    return this.$store.state.settings.theme === SYSTEM;
                }
            },

            appVersion: {
                get() {
                    return this.$store.state.appVersion;
                }
            },

            hasTouch: {
                get() {
                    return this.$store.state.hasTouch;
                }
            },

            activePuzzleName: {
                get() {
                    return this.$store.state.activePuzzleName;
                }
            },

            isPuzzleEmpty: {
                get() {
                    return this.grid.isEmpty || this.activePuzzleName === null;
                }
            },

            showKeyboard: {
                get() {
                    return this.$store.state.settings.showKeyboard;
                }
            },

            needsKeyboard: {
                get() {
                    return this.$store.state.needsKeyboard;
                }
            },

            difficulty: {
                get() {
                    return this.$store.state.settings.difficulty;
                }
            },

            percentComplete: {
                get() {
                    const played = this.grid.playedCells.length;
                    const playable = this.grid.playableCells.length;
                    return Math.round((played / playable) * 100);
                }
            },

            puzzleDataAsJSON: {
                get() {
                    return JSON.stringify(this.puzzleDataAsObject);
                }
            },

            activePuzzleURL: {
                get() {
                    const data = this.puzzleDataAsObject();
                    const url = new URL(window.location);

                    url.searchParams.set('d', data.d);

                    if (data.c !== undefined) {
                        url.searchParams.set('c', data.c);
                    }

                    return url;
                }
            },

            updateQRCodeHandler: {
                get() {
                    return () => this.updateQRCodeURL();
                }
            },

            puzzlePosition: {
                get() {
                    return this.$store.state.settings.puzzlePosition;
                },

                set(value) {
                    this.$store.commit({
                        name: 'puzzlePosition',
                        type: 'updateSettings',
                        value
                    });
                }
            }
        },

        created() {
            this.resizeTimeout = null;
            this.resizeDebounce = 250;
        },

        mounted() {
            this.$nextTick(async function mounted() {
                this.setTheme();

                let puzzleNameToLoad = null;

                const { origin, pathname } = window.location;
                const windowURL = new URL(window.location);
                const searchParams = windowURL.searchParams;

                const hasSearchParams = Array.from(searchParams).length > 0;

                if (hasSearchParams) {
                    puzzleNameToLoad = await this.processSearchParams(searchParams);
                    window.history.replaceState({}, document.title, `${origin}${pathname}`);
                }

                if (!puzzleNameToLoad) {
                    puzzleNameToLoad = await settingsStorage.getItem('activePuzzleName');
                }

                if (puzzleNameToLoad) {
                    await this.loadPuzzle(puzzleNameToLoad);
                } else {
                    this.createNewPuzzle();
                }
            });
        },

        methods: {
            puzzleDataAsObject() {
                const puzzle = this.$store.state.activePuzzle;

                const result = {
                    d: `${puzzle.index}.${puzzle.difficulty}.${puzzle.data}`
                };

                if (puzzle.changes.length) {
                    const rcHistMap = new Map();

                    puzzle.changes.forEach(change => {
                        const [r, c, value] = change.split('');
                        const key = `${r}${c}`;

                        if (rcHistMap.has(key)) {
                            const rcValues = rcHistMap.get(key);
                            rcValues.push(value);
                            rcHistMap.set(key, rcValues);
                        } else {
                            rcHistMap.set(key, [value]);
                        }
                    });

                    const hist = [];
                    rcHistMap.forEach((value, key) => {
                        const mostRecentValue = value[0];
                        if (mostRecentValue > 0) {
                            hist.push(`${key}${mostRecentValue}`);
                        }
                    });

                    result.c = hist.join('');
                }

                return result;
            },

            async processSearchParams(searchParams) {
                let puzzleName = null;

                if (searchParams.has('d')) {
                    const pzData = searchParams.get('d').split('.');

                    if (pzData.length && pzData.length === 3) {
                        const [indexStr, difficulty, data] = pzData;

                        const params = this.createParams(Number(indexStr));
                        const { name, index, file, start, end } = params;
                        puzzleName = name;

                        const puzzle = {
                            index,
                            data,
                            difficulty: difficulty,
                            changes: [],
                            file: `${file}.${params.blockIndex}.${start}.${end}`,
                            created: DateTime.utc().toMillis()
                        };

                        if (searchParams.has('c')) {
                            const changeStr = searchParams.get('c');

                            if (changeStr.length > 0 && changeStr.length % 3 === 0) {
                                const changes = Array.from(changeStr);

                                while (changes.length) {
                                    const change = changes.splice(0, 3).join('');
                                    puzzle.changes.push(`${change}0`);
                                }
                            }
                        }

                        this.resetGrid();

                        await puzzleStorage.setItem(puzzleName, puzzle);
                        this.$store.commit('setActivePuzzleName', puzzleName);
                        this.$store.commit('setActivePuzzle', puzzle);
                    }
                }

                return puzzleName;
            },

            setTheme() {
                const theme = this.$store.state.settings.theme;

                if (theme === SYSTEM) {
                    const preference = window.matchMedia('(prefers-color-scheme: dark)');
                    this.$vuetify.theme.dark = preference.matches;
                } else {
                    this.$vuetify.theme.dark = theme === DARK;
                }
            },

            adjustPuzzlePosition(direction) {
                if (direction === 'left') {
                    if (this.puzzlePosition === 'end') {
                        this.puzzlePosition = 'center';
                    } else if (this.puzzlePosition === 'center') {
                        this.puzzlePosition = 'start';
                    }
                } else if (direction === 'right') {
                    if (this.puzzlePosition === 'start') {
                        this.puzzlePosition = 'center';
                    } else if (this.puzzlePosition === 'center') {
                        this.puzzlePosition = 'end';
                    }
                }
            },

            showKeyboardSettingChanged(showing) {
                if (this.activeCell !== null) {
                    this.activeCell.isSelected = showing;
                }
                this.onResize();
            },

            onResize(event) {
                if (event === undefined) {
                    /* Initial sizing setup */
                    this.handleResize();
                } else {
                    /* Resize handling (debounced) */
                    clearTimeout(this.resizeTimeout);

                    this.resizeTimeout = setTimeout(
                        this.handleResize.bind(this),
                        this.resizeDebounce
                    );
                }
            },

            handleResize() {
                this.displayGrid = false;

                const appBarHeight = 48 + 22;
                const padding = this.$vuetify.breakpoint.xsOnly ? 4 : 12;

                const width = this.$vuetify.breakpoint.width - (padding * 2);
                const height = this.$vuetify.breakpoint.height - (padding * 2) - appBarHeight;

                let numCells = 9;
                const size = Math.min(width, height);

                if (this.showKeyboard) {
                    if (width > height) {
                        numCells = 12;
                    }
                }

                const cellSize = Math.floor(enforceBounds(size / numCells, 30, 68));
                let cellFontSize = Math.floor(cellSize / 1.62);
                let cellCandidatesSize = Math.floor(cellSize / 4);

                if (this.$vuetify.breakpoint.xsOnly) {
                    cellFontSize = Math.floor(cellSize / 1.62);
                    cellCandidatesSize = Math.ceil(cellSize / 3.25);
                }

                this.$nextTick(() => {
                    this.$el.style.setProperty('--cell-size', `${cellSize}px`);
                    this.$el.style.setProperty('--cell-font-size', `${cellFontSize}px`);
                    this.$el.style.setProperty('--possibles-font-size', `${cellCandidatesSize}px`);

                    this.displayGrid = true;
                });
            },

            blockIndex(r, c) {
                return 3 * Math.trunc(r / 3) + Math.trunc(c / 3);
            },

            handleCellDigitInput(newValue) {
                let nextValue = newValue;

                const currentValue = this.activeCell.gridCell.digit;

                if (newValue !== null && currentValue !== null) {
                    if (newValue === currentValue) {
                        nextValue = null;
                    }
                }

                this.activeCell.gridCell.digit = nextValue;
            },

            onKeyDown(event) {
                const setDigitKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
                const clearDigitKeys = ['0', 'Backspace', 'Escape'];

                const arrowKeyMap = {
                    ArrowUp: UP,
                    ArrowDown: DOWN,
                    ArrowLeft: LEFT,
                    ArrowRight: RIGHT
                };

                if (this.activeCell !== null) {
                    if (!this.activeCell.isImpossible) {
                        if (setDigitKeys.includes(event.key)) {
                            this.handleCellDigitInput(Number(event.key));
                        } else if (clearDigitKeys.includes(event.key)) {
                            this.handleCellDigitInput(null);
                        }
                    }

                    if (event.key in arrowKeyMap) {
                        this.onTouchKeyArrow(arrowKeyMap[event.key]);
                    } else if (event.key === 'Enter') {
                        this.onCellSelect(this.activeCell);
                    }
                }
            },

            onTouchKeyDigit(digit) {
                if (this.activeCell !== null) {
                    this.handleCellDigitInput(digit);
                }
            },

            onTouchKeyBackspace() {
                if (this.activeCell !== null) {
                    this.activeCell.gridCell.digit = null;
                }
            },

            onTouchKeyReturn() {
                this.onCellSelect(this.activeCell);
            },

            onTouchKeyArrow(direction) {
                if (this.activeCell !== null) {
                    let { rowIndex: r, colIndex: c } = this.activeCell;

                    if (direction === RIGHT) {
                        c = c < 8 ? c + 1 : 0;
                        if (c === 0) {
                            r = r < 8 ? r + 1 : 0;
                        }
                    } else if (direction === LEFT) {
                        c = c > 0 ? c - 1 : 8;
                        if (c === 8) {
                            r = r > 0 ? r - 1 : 8;
                        }
                    } else if (direction === UP) {
                        r = r > 0 ? r - 1 : 8;
                        if (r === 8) {
                            c = c > 0 ? c - 1 : 8;
                        }
                    } else if (direction === DOWN) {
                        r = r < 8 ? r + 1 : 0;
                        if (r === 0) {
                            c = c < 8 ? c + 1 : 0;
                        }
                    }

                    this.activeCell.isSelected = false;
                    this.setActiveCell(this.$refs[`${r}:${c}`][0]);
                }
            },

            setActiveCell(cell) {
                this.activeCell = cell;
                this.activeCell.isSelected = true;
            },

            onCellSelect(cell) {
                if (this.activeCell === null) {
                    this.setActiveCell(cell);
                } else if (this.activeCell !== cell) {
                    this.activeCell.isSelected = false;
                    this.setActiveCell(cell);
                } else {
                    this.activeCell.isSelected = false;
                    this.activeCell = null;
                }
            },

            rewind() {
                const changes = this.$store.state.activePuzzle.changes;

                if (changes != null && changes.length) {
                    // eslint-disable-next-line no-unused-vars
                    const [row, col, _, old] = Array.from(changes[0], Number);

                    this.$store.commit('removeChange');

                    const cell = this.grid.cell(row, col);

                    if (cell !== null) {
                        cell.value = old;
                        this.grid.loadPossibleValues();
                    }
                }
            },

            onCellChanged(change) {
                if (change.type === 'digit') {
                    this.$store.commit('addChange', change);
                }
            },

            async updateQRCodeURL() {
                const url = this.activePuzzleURL;
                const dataURL = await QRCode.toDataURL(url.toString(), {
                    margin: 2
                });
                this.$store.commit('updateQRCodeURL', dataURL);
            },

            copyToClipboard() {
                if (window.isSecureContext) {
                    navigator.clipboard.writeText(this.activePuzzleURL);
                }
            },

            resetGrid() {
                if (this.activeCell !== null) {
                    this.activeCell.isSelected = false;
                    this.activeCell = null;
                }

                this.grid.reset();

                this.$store.commit('setActivePuzzleName', null);
                this.$store.commit('resetChanges');
            },

            async loadStoredPuzzles() {
                const result = [];
                await puzzleStorage.iterate((value, name) => {
                    if (name !== this.activePuzzleName) {
                        result.push({ name, ...value });
                    }
                });

                this.$store.commit('loadStoredPuzzles', result);
            },

            restartPuzzle() {
                this.$store.commit('setDisplayCells', false);

                if (this.activeCell !== null) {
                    this.activeCell.isSelected = false;
                    this.activeCell = null;
                }

                this.grid.restart();

                this.$store.commit('clearChanges');
                this.$store.commit('setDisplayCells', true);
            },

            async loadPuzzle(name) {
                if (typeof name === 'string') {
                    this.$store.commit('setDisplayCells', false);

                    this.resetGrid();
                    this.grid.autoLoadPossibles = false;

                    this.$store.commit('setActivePuzzleName', name);

                    const puzzle = await puzzleStorage.getItem(
                        this.$store.state.activePuzzleName
                    );

                    if (puzzle) {
                        this.$store.commit('setActivePuzzle', puzzle);

                        this.grid.fromString(puzzle.data, false);

                        if (puzzle.changes) {
                            /* Apply changes in the same order they were made... */
                            puzzle.changes.reverse().forEach((change) => {
                                const [row, col, value] = Array.from(change, Number);

                                const cell = this.grid.cell(row, col);
                                if (cell !== null) {
                                    cell.value = value > 0 ? value : null;
                                }
                            });
                        }

                        this.updateQRCodeURL();

                        this.grid.loadPossibleValues();
                        this.grid.autoLoadPossibles = true;
                    }

                    this.$store.commit('setDisplayCells', true);
                }
            },

            async createNewPuzzle() {
                try {
                    const url = new URL('/api/createNewPuzzle', window.location.href);
                    url.searchParams.append('difficulty', this.difficulty);

                    const response = await fetch(url);

                    if (response.ok) {
                        const data = await response.json();
                        console.log(data);

                        const puzzle = {
                            index: data.index,
                            data: data.puzzle,
                            difficulty: this.difficulty,
                            changes: [],
                            file: data.file,
                            created: DateTime.utc().toMillis()
                        };

                        await puzzleStorage.setItem(data.name, puzzle);
                        this.$store.commit('setActivePuzzle', puzzle);

                        this.loadPuzzle(data.name);
                    } else {
                        console.log(response.statusText);
                    }
                } catch (err) {
                    // Should never get here...
                    console.log(err);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

    div.puzzle {
        font-family: var(--puzzle-font-family);
        font-size: var(--puzzle-font-size);
        font-weight: var(--puzzle-font-weight);
    }

    .puzzle-container {
        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }

    .app-version {
        font-size: 0.75em;
    }

    .fade-enter-active {
        transition: opacity 0.75s;
    }

    .fade-leave-active {
        transition: opacity 0.75s 3s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

<style lang="scss">
    :root {
        --puzzle-font-family: "Lato";
        --puzzle-font-size: 0;
        --puzzle-font-weight: 400;
        --touch-action: none;

        --cell-color: white;
        --cell-size: 0;
        --cell-font-size: 0;
        --cell-selected-color: var(--v-primary-lighten4); //#c5cae9;
        --cell-shaded-color: var(--v-secondary-lighten4); //#f1f1f1;
        --cell-invalid-color: #ce969a; // #aa000055;
        --cell-inner-border: 1px solid var(--v-secondary-lighten2); //#cfcfcf; //#C62828;//#43A047;//#CFCFCF;
        --cell-outer-border: 2px solid var(--v-secondary-lighten1);//#a8a8a8; //#C62828;//#43A047;//#aFaFaF;

        --given-font-color: var(--v-secondary-darken2);
        --possibles-font-color: var(--v-secondary-darken2);
        --possibles-font-size: 0;
        --modified-font-color: #0039DB; // #0042FF; // var(--v-primary-base);//#0042FF; //var(--v-info-darken3);
        --invalid-font-color: #aa0000;
        --conflict-indicator-font-color: var(--v-secondary-darken3);

        --key-digit-font-color: var(--v-secondary-darken1);
        --key-control-font-color: var(--v-secondary-darken1);
        --key-inactive-font-color: var(--v-secondary-lighten2);
        --key-inactive-color: var(--v-secondary-lighten5);
        --key-border: 2px solid var(--v-secondary-lighten2);
        --key-active-border: 1px solid var(--modified-font-color);
    }

    :root .theme--dark.v-application {
        --cell-color: #1f1f1f;
        --cell-shaded-color: #292929;
        --cell-inner-border: 1px solid #343434;
        --cell-outer-border: 2px solid #323232;
        --cell-selected-color: var(--v-secondary-darken3);
        --cell-invalid-color: #590c0d; // #aa000077;

        --given-font-color: #b3b3b3; // #FFFFFF99;
        --possibles-font-color: #b3b3b3; // #FFFFFF99;
        --modified-font-color: #9EB7FF;
        --invalid-font-color: #9c4a12; // var(--v-error-darken2); // #FF0000AA;
        --conflict-indicator-font-color: #b3b3b3;
        --key-digit-font-color: var(--v-secondary-base);
        --key-border: 2px solid #343434;
        --key-active-border: 1px solid var(--modified-font-color);
    }

    html, body {
        touch-action: var(--touch-action);
        overflow: hidden;
        height: 100%;
        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }

    .theme--light.v-application .v-toolbar__title {
        color: white;
    }

    .v-toolbar__title {
        cursor: pointer;
    }

    .theme--dark.v-card {
        background-color: #313131;
    }

    .row {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .theme--dark.v-application .v-app-bar {
        background: var(--v-secondary-darken4) !important;
        color: var(--v-primary-lighten1);
    }

    .theme--light.v-application .settings-menu-button {
        color: white;
    }

    .theme--dark.v-application .settings-menu-button {
        color: var(--v-primary-lighten1);
    }

    .theme--light.v-application .puzzle-menu-button {
        color: var(--v-primary-lighten5);
    }

    .theme--dark.v-application .puzzle-menu-button {
        color: var(--v-primary-lighten1);
    }

    .theme--dark.v-application .v-chip--active {
        color: black;
    }
</style>
