<template>
    <div />
</template>

<script>
    export default {
        created() {
            this.boundHandlePreferenceChange = this.handlePreferenceChange.bind(this);

            this.preferenceQuery = window.matchMedia('(prefers-color-scheme: dark)');
            this.preferenceQuery.addListener(this.boundHandlePreferenceChange);
        },

        beforeDestroy() {
            this.preferenceQuery.removeListener(this.boundHandlePreferenceChange);
            this.preferenceQuery = null;
        },

        methods: {
            handlePreferenceChange(query) {
                this.$emit('prefers-color-scheme', query.matches);
            }
        }
    };
</script>

<style lang="scss" scoped>
    div {
        display: none;
    }
</style>
