<template>
    <v-container class="ma-0 pa-0 fill-height">
        <v-row
            v-for="(alignment, i) in ['start', 'center', 'end']"
            :key="i"
            :align="alignment"
            justify="center"
            no-gutters
        >
            <v-col
                v-for="j in 3"
                :key="j"
            >
                <div
                    class="possible"
                    :class="possibleStyleClasses(i * 3 + j)"
                >
                    {{ possibles.get(i * 3 + j) }}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { RequiredObjectType } from '../app/constants';

    export default {
        props: {
            possibles: RequiredObjectType
        },

        methods: {
            possibleStyleClasses(value) {
                return {
                    empty: !this.possibles.has(value),
                    'breakpoint-xs': this.$vuetify.breakpoint.xsOnly
                };
            }
        }
    };
</script>

<style lang="scss" scoped>
    div.possible {
        font-size: var(--possibles-font-size);
        text-align: center;
        line-height: 1;
        color: var(--possibles-font-color);
        font-weight: 300;
    }

    div.possible.breakpoint-xs {
        font-weight: 700;
    }

    div.possible.empty::before {
        content: "\200B";
    }
</style>
