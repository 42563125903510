<template>
    <div />
</template>

<script>
    export default {
        data() {
            return {
                keyup: null,
                keypress: null,
                keydown: null
            };
        },

        created() {
            if (this.$listeners.keyup !== undefined) {
                this.keyup = function keyup(e) {
                    this.$emit('keyup', e);
                }.bind(this);

                window.addEventListener('keyup', this.keyup);
            }

            if (this.$listeners.keypress !== undefined) {
                this.keypress = function keypress(e) {
                    this.$emit('keypress', e);
                }.bind(this);

                window.addEventListener('keypress', this.keypress);
            }

            if (this.$listeners.keydown !== undefined) {
                this.keydown = function keydown(e) {
                    this.$emit('keydown', e);
                }.bind(this);

                window.addEventListener('keydown', this.keydown);
            }
        },

        beforeDestroy() {
            if (this.keyup !== null) {
                if (window !== undefined) {
                    window.removeEventListener('keyup', this.keyup);
                };
                this.keyup = null;
            }

            if (this.keypress !== null) {
                if (window !== undefined) {
                    window.removeEventListener('keypress', this.keypress);
                }
                this.keypress = null;
            }

            if (this.keydown !== null) {
                if (window !== undefined) {
                    window.removeEventListener('keydown', this.keydown);
                }
                this.keydown = null;
            }
        }
    };
</script>

<style lang="scss" scoped>
    div {
        display: none;
    }
</style>
