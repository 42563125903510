import Vue from 'vue';
import vuetify from './plugins/vuetify';

import App from './App';
import store from './store';
import settingsStorage from './app/storage/settings-storage';

window.addEventListener('load', async function onload() {
    const fontSet = window.document.fonts;

    // appFonts.forEach(appFont => {
    //     fontSet.add(appFont);
    //     appFont.load();
    // });

    await fontSet.ready;

    const vm = new Vue({
        store,
        vuetify,
        render: render => render(App)
    });

    const settings = await settingsStorage.getItem('settings');

    if (settings) {
        vm.$store.commit('loadSettings', settings);
    } else {
        vm.$store.commit('initSettings');
    }

    vm.$mount('#app');
    window.app = vm.$children[0];
    window.grid = window.app.grid;
});
