<template>
    <v-container
        class="touch-keyboard-container fill-height ma-0 pt-3 px-0 pb-0"
        @click.stop.prevent
        @selectstart.stop.prevent
    >
        <v-row
            v-for="(_, r) in 3"
            :key="r"
            class="pb-1"
            no-gutters
            :justify="keyboardPosition"
            align="center"
        >
            <v-col cols="auto">
                <v-btn
                    v-if="r === 0 && keyboardPosition !== 'start'"
                    class="pa-0"
                    :x-small="$vuetify.breakpoint.xsOnly"
                    icon
                    color="secondary lighten-1"
                    @click="moveKeyboard('left')"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col
                class="d-flex"
                cols="auto"
            >
                <!-- Digit Keys -->
                <div
                    v-for="(__, c) in 3"
                    :key="`key-digit-${r}:${c}`"
                    v-ripple="ripple"
                    class="key-container rounded-circle"
                    :class="digitKeysContainerClasses(r, c)"
                    @selectstart.prevent
                    @pointerdown.capture="onDigitKeyPointerDown(calcKeyNum(r, c))"
                >
                    <v-container class="fill-height ma-0 pa-0">
                        <v-row
                            no-gutters
                            justify="center"
                        >
                            <v-col cols="auto">
                                <div
                                    class="key digit"
                                    :class="digitKeysClasses(r, c)"
                                >
                                    {{ calcKeyNum(r, c) }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <!-- Control Keys -->
                <div
                    v-if="settings.showKeyboardControlKeys"
                    class="d-flex control-keys-wrapper"
                >
                    <div
                        v-for="(___, i) in 2"
                        :key="`key-control-${r}:${i}`"
                        v-ripple="ripple"
                        class="key-container rounded-circle"
                        :class="controlKeysClasses(r, i)"
                        @selectstart.prevent
                        @pointerdown="onControlKeyPointerDown(r, i)"
                    >
                        <v-container class="fill-height ma-0 pa-0">
                            <v-row
                                no-gutters
                                justify="center"
                            >
                                <v-col cols="auto">
                                    <div
                                        :id="`key-${r}-${i}`"
                                        class="key control"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="auto"
            >
                <v-btn
                    v-if="r === 0 && keyboardPosition !== 'end'"
                    class="pa-0"
                    :x-small="$vuetify.breakpoint.xsOnly"
                    icon
                    color="secondary lighten-1"
                    @click="moveKeyboard('right')"
                >
                    <v-icon>
                        mdi-arrow-right
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { UP, DOWN, LEFT, RIGHT } from '../app/constants';
    export default {
        props: {
            keyboardPosition: {
                type: String,
                required: true,
                default: 'center'
            },
            activeCell: {
                type: Object
            }
        },

        computed: {
            settings: {
                get() {
                    return this.$store.state.settings;
                }
            },

            ripple: {
                get() {
                    const result = {
                        center: false,
                        class: 'primary--text'
                    };
                    return this.activeCell ? result : null;
                }
            }
        },

        methods: {
            calcKeyNum(r, c) {
                return 1 + c + r * 3;
            },

            moveKeyboard(direction) {
                this.$emit('move-keyboard', direction);
            },

            checkActiveCell(r, c) {
                let result = false;

                if (this.activeCell) {
                    const cell = this.activeCell.gridCell;

                    if (!cell.isGiven) {
                        if (cell.hasValue) {
                            const activeDigit = cell.digit;

                            if (this.calcKeyNum(r, c) === activeDigit) {
                                result = true;
                            }
                        } else {
                            if (this.settings.showPossibles) {
                                if (cell.possibles.values.has(this.calcKeyNum(r, c))) {
                                    result = true;
                                }
                            }
                        }
                    }
                }

                return result;
            },

            digitKeysContainerClasses(r, c) {
                return {
                    inactive: !this.activeCell,
                    'ml-1': c === 0,
                    'mx-1': c === 1,
                    'mr-1': c === 2,
                    'active-digit': this.checkActiveCell(r, c)
                };
            },

            digitKeysClasses(r, c) {
                return {
                    'active-digit': this.checkActiveCell(r, c)
                };
            },

            controlKeysClasses(r, i) {
                return {
                    inactive: !this.activeCell,
                    'ml-3': i === 0,
                    'mx-1': i === 1
                };
            },

            onControlKeyPointerDown(r, i) {
                if (this.activeCell) {
                    if (r === 0 && i === 0) {
                        this.$emit('touch-key-backspace');
                    } else if (r === 2 && i === 0) {
                        this.$emit('touch-key-return');
                    } else if (r === 0 && i === 1) {
                        this.$emit('touch-key-arrow', UP);
                    } else if (r === 1 && i === 0) {
                        this.$emit('touch-key-arrow', LEFT);
                    } else if (r === 1 && i === 1) {
                        this.$emit('touch-key-arrow', RIGHT);
                    } else if (r === 2 && i === 1) {
                        this.$emit('touch-key-arrow', DOWN);
                    }
                }
            },

            onDigitKeyPointerDown(digit) {
                if (this.activeCell) {
                    this.$nextTick(() => {
                        this.$emit('touch-key-digit', digit);
                    });
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

    .key-container {
        flex-grow: 0;
        flex-shrink: 0;
        width: var(--cell-size);
        height: var(--cell-size);
        background: var(--cell-color);
        font-size: var(--cell-font-size);
        line-height: var(--cell-size);
        cursor: default;
        border: var(--key-border);
    }

    .key-container.active-digit {
        border: var(--key-active-border);
    }

    .key.digit.active-digit {
        color: var(--modified-font-color) !important;
    }

    .key.digit {
        color: var(--key-digit-font-color);
        line-height: 1;
        font-weight: 400;
    }

    .key.control {
        color: var(--key-digit-font-color);
        font-family: "Material Design Icons";
        font-feature-settings: 'liga';
        font-size: 24px;
        line-height: 1;
    }

    .theme--light.v-application .key-container {
        background: var(--cell-shaded-color);
    }

    .theme--dark.v-application .key-container {
        background: #232323; // var(--cell-color);
    }

    .theme--dark.v-application .key.digit {
        color: var(--key-digit-font-color);
    }

    .theme--light.v-application .key.digit {
        color: var(--key-digit-font-color);
    }

    #key-0-0::before {
        position: relative;
        left: -1px;
        top: -1px;
        content: "\F0B5C"; // "\F006E"; // mdi-backspace
    }

    #key-0-1::before {
        font-size: 30px;
        content: "\F0737"; // mdi-arrow-up-bold
    }

    #key-1-0::before {
        font-size: 30px;
        content: "\F0731"; // mdi-arrow-left-bold
    }

    #key-1-1::before {
        font-size: 30px;
        content: "\F0734"; // mdi-arrow-right-bold
    }

    #key-2-0::before {
        font-size: 30px;
        content: "\F0311"; // mdi-keyboard-return
    }

    #key-2-1::before {
        font-size: 30px;
        content: "\F072E"; // mdi-arrow-down-bold
    }
</style>
